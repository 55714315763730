import React, {useState} from "react"

import img1 from "assets/images/news-1.png"
import img2 from "assets/images/news-2.png"
import img3 from "assets/images/news-3.png"
import "./NewsHome.scss"
import {SimpleButton} from "../SimpleButton";
import {routes} from "../../utils";
import {Input} from "../Input";
import {apiOur} from "../../service/api/apiOur";
import toast from "react-hot-toast";

export const NewsHome = (): JSX.Element => {
  const [email, setEmail] = useState('')
  const blocks = [
    {
      title: "StakedGain Launches with $500,000 Seed Investment, Redefining Crypto Staking",
      desc: "In a notable advancement for decentralized finance (DeFi), StakedGain is thrilled to announce the successful closure of a $500,000 seed funding round. This substantial investment underscores the venture capital community's confidence in StakedGain's innovative approach to DeFi and its potential to redefine the cryptocurrency staking landscape.",
      link:"https://finance.yahoo.com/news/stakedgain-launches-500-000-seed-213000734.html",
      source: "Yahoo!Finance",
      icon: img3,
    },
    {
      title:"StakedGain Skyrockets to $105 Million TVL, Revolutionizing DeFi Staking in Record Time",
      desc:"Just three weeks after its launch, StakedGain has shattered " +
        "expectations, soaring to a total value locked (TVL) of $105 million. This " +
        "remarkable growth not only cements StakedGain's position as a frontrunner in " +
        "the decentralized finance (DeFi) staking arena but also signifies a seismic " +
        "shift in how digital assets are managed and grown within the blockchain " +
        "ecosystem.",
      icon: img2,
      link: "https://www.binance.com/en/square/post/8054617579817",
      source: 'Binance',
    },
    {
      title:"StakedGain Celebrates Major Milestones and Announces Exciting Developments",
      desc: "StakedGain, a Rising Star in the Cryptocurrency Space, Continues to Defy Market Trends with Innovative Services and Rapid Growth\n" +
        "Just two months after its public launch, StakedGain has already achieved several significant milestones and announced exciting developments. In this short time, StakedGain's Total Value Locked (TVL) has surged, reaching an impressive $142 million.",
      link: "https://coinmarketcap.com/community/articles/66478715190fd26b0d0d20cb/",
      source: 'CoinMarketCap',
      icon: img1,
    },
  ]
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email.length) {
      return;
    }
    await toast.promise(
      apiOur.addRefAddress({user: email, follower: email}).then(() => setEmail('')),
      {
        loading: 'Sending request...',
        success: <b>Thanks for your subscribe! ✅</b>,
        error: e => <b>{e.message}</b>,
      },
    )
  }
  return (
    <div className="news-home">
      <h3 data-aos="fade-up" className="news-home-title">In the News</h3>
      <div data-aos="fade-up" className="news-home-content">
        {blocks.map((block, index) => (
          <a href={block.link} target='_blank' rel='noreferrer noopener' key={index} className="news-home-content-item">
            <div className="news-home-content-item-icon">
              <img alt="news-home" src={block.icon}/>
            </div>
            <div className="news-home-content-item-title">{block.title}</div>
            <div className="news-home-content-item-desc">{block.desc}</div>
            <div className="news-home-content-item-footer">From <b>{block.source}</b></div>
          </a>
        ))}
      </div>
      <div className='news-home-btn'>
        <SimpleButton text='More Crypto News' href={routes.news} variant='colored'/>
      </div>
      <p>
        Stay updated for the latest news, drops and programs.
      </p>
      <form onSubmit={onSubmit} className='news-home-input'>
        <Input variant='dark' onChange={value => setEmail(value)} value={email} placeholder='Email address'
               type='email'/>
        <SimpleButton text='Subscribe' type='submit'/>
      </form>
    </div>
  )
}
