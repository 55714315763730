import React from "react"

import "./Main.scss"
import {  useSearchParams } from "react-router-dom"
import { routes } from "../../utils"
import { useMedia } from "use-media"
import srcPath from "assets/pdfs/pdf.pdf"
import {SimpleButton} from "../SimpleButton";
import { TypeAnimation } from 'react-type-animation';


export const Main = (): JSX.Element => {
  const [search] = useSearchParams()
  const isM = useMedia({ maxWidth: 1200 })

  return (
    <main className="main animate__animated animate__fadeInLeft">
      <div className="main-left-first">Amplify Your Multi-Chain Staking</div>
      <h1 className="main-left-title">
        Unlock Limitless Earnings with <span className="colored">Stakedgain</span>: Your Trusted Path to Decentralized
        Staking, by <div className='main-left-title-animate'>
        <TypeAnimation
          sequence={[
            'Advanced',
            2000,
            'Believable',
            2000,
          ]}
          wrapper="span"
          speed={30}
          className='type'
          repeat={Infinity}
        />
      </div> Smart Contracts.
      </h1>
      <p className="main-left-desc">Stake, Earn, Soar with Stakedgain.</p>
      <div className="main-left-buttons">
        <SimpleButton text="Stake NOW" href={`${routes.farms}?${search.toString()}`} />
        {isM ? (
          <a download href={srcPath} className="main-left-buttons-item right">
            Litepaper
          </a>
        ) : (
          <SimpleButton variant='outlined' text='Litepaper' href={`${routes.docs}?${search.toString()}`} />
        )}
      </div>
    </main>
  )
}
