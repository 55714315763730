import React, { useEffect, useState } from "react"
import { Header, Footer, Table } from "components"
import { AnnouncementModal, CancelModal, TransferModalUsdc } from "../../modals"

import "./Farms.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"
import cn from "classnames"
import {TransferModal} from "../../modals/TransferModal";
import {apiOur} from "../../service/api/apiOur";
import { useAccount } from "wagmi"
import { TransferModalWbtc } from "../../modals/TransferModalWbtc"

const tabs = ["All Pools", "Staked Pools"]

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const Farms = (): JSX.Element => {
  const [tab, setTab] = useState("All Pools")
  const [cancel, setCancel] = useState(false)
  const [codeString, setCodeString] = useState<string | undefined>(undefined)
  const [transferModal, setTransferModal] = useState<any | undefined>(undefined)
  const [transferModalUsdc, setTransferModalUsdc] = useState<any | undefined>(undefined)
  const [transferModalWbtc, setTransferModalWbtc] = useState<any | undefined>(undefined)
  const [announcement, setAnnouncement] = useState(false)
  const [claimAnn, setClaimAnn] = useState(false)
  const [accountRematch, setAccountRematch] = useState('')
  const { address } = useAccount();

  useEffect(() => {
    const code = getCookie('code')
    setCodeString(code || '')
  }, [])

  useEffect(() => {
    if (address) {
      apiOur.getWithdrawals(`CLOSE_ANN_${address}`).then(r => {
        if (!r.length) {
          setAnnouncement(true)
        }
      })
    } else {
      setAnnouncement(true)
    }
  }, [address])


  return (
    <div className="farms" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      <Header/>
      <div className='news-prices'>
        {
          // @ts-ignore
          (<coingecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,weth,binancecoin,usd-coin,uniswap,chainlink,wrapped-bitcoin,apecoin,tether,pancakeswap-token,baby-doge-coin,trust-wallet-token,stepn,coin98,aptos,optimism,matic-network,avalanche-2,arbitrum,chainlink,manta-network,fantom,dydx-chain" currency="usd"
                                                font-color="#ffffff"
                                                background-color="rgba(12, 14, 40, 0.32)"
                                                locale="en"/>)
        }
      </div>

      <div className="farms-content">
        <div className="farms-content-header">
          <div className="farms-content-header-tabs">
            {tabs.map((item, index) => (
              <button
                key={index}
                className={cn("farms-content-header-tabs-item", {
                  active: tab === item,
                })}
                onClick={() => {
                  setTab(item)
                }}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="right-ll"/>
        </div>
        {codeString !== undefined && (
          <Table openTransferWbtc={(current, money, interests, length) => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setTransferModalWbtc({
              plan: current,
              money,
              interests,
              totalLength: length,
            })
          }} openClaimAnn={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setClaimAnn(true)
          }} openCancel={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setCancel(true)
          }} stakedDisplay={tab === "Staked Pools"}
                 isNewCode={codeString === 'cplCV0'} openTransfer={(current, money, interests, length) => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setTransferModal({
              plan: current,
              money,
              interests,
              totalLength: length,
            })
          }}
                 openTransferUsdc={(current, money, interests, length) => {
                   window.scrollTo({
                     top: 0,
                     behavior: "smooth",
                   })
                   setTransferModalUsdc({
                     plan: current,
                     money,
                     interests,
                     totalLength: length,
                   })
                 }}/>
        )}
      </div>
      {cancel && <CancelModal onClose={() => setCancel(false)}/>}
      {announcement && <AnnouncementModal neverRemind text="Official Announcement: StakedGain Platform Now Accessible via stakedgain.finance
Dear Users,
We are pleased to inform you that StakedGain will now primarily operate through the domain stakedgain.finance. This domain will serve as the primary access point for all staking activities and platform-related services.
Our team has worked diligently to ensure that this transition is seamless for all users, and we are confident that this change will enhance the overall platform experience. Rest assured, all funds remain secure within blockchain-based smart contracts, and this update does not affect your assets or ongoing staking activities.
What You Need to Know:
Please update your bookmarks to stakedgain.finance for easy access.
All features, services, and staking pools remain fully operational.
Our previous domain will continue to redirect users for a limited time, but we recommend using the new domain moving forward for the best experience.
We appreciate your continued trust and support. If you have any questions or encounter any issues, please don’t hesitate to reach out to our support team.
Thank you for being part of the StakedGain community!
Sincerely,
The StakedGain Team" onClose={() => setAnnouncement(false)}/>}
      {claimAnn && <AnnouncementModal
          text='Due to current high levels of congestion on the Ethereum network, transaction times may be longer than usual. All transactions should be confirmed within the next 12 hours.'
          onClose={() => setClaimAnn(false)}/>}
      {!!transferModal && <TransferModal addressConnect={accountRematch} onClose={() => {
        setTransferModal(undefined)
        location.reload()
      }}
                                         currentPlan={transferModal.plan} totalLength={transferModal.totalLength} currentMoney={transferModal.money}
                                         interests={transferModal.interests}/>}
      {!!transferModalUsdc && <TransferModalUsdc addressConnect={accountRematch} onClose={() => {
        setTransferModalUsdc(undefined)
        location.reload()
      }}
                                         currentPlan={transferModalUsdc.plan} totalLength={transferModalUsdc.totalLength} currentMoney={transferModalUsdc.money}
                                         interests={transferModalUsdc.interests}/>}
      {!!transferModalWbtc && <TransferModalWbtc addressConnect={accountRematch} onClose={() => {
        setTransferModalWbtc(undefined)
        location.reload()
      }}
                                         currentPlan={transferModalWbtc.plan} totalLength={transferModalWbtc.totalLength} currentMoney={transferModalWbtc.money}
                                         interests={transferModalWbtc.interests}/>}
      <Footer/>
    </div>
  )
}
