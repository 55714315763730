import { useSearchParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import {
  Header,
  Footer,
  Main,
  Features,
  Ecosystem,
  Loading,
  Partners,
  Investors,
  NewsHome,
  Leverage,
  Chains, Code, SwapHome
} from "components"
import {  CookiesModal, AnnouncementModal } from "../../modals"
import videoSrc from "assets/video/bg.mp4"

import "./Home.scss"
import { getFromLocalStorage, setToLocalStorage } from "../../utils"
import {preloadDataBsc} from "../SwapInfo/preloadDataBsc";
import {preloadData} from "../SwapInfo/preloadData";
import imgMainBgSrc from "../../assets/images/main-bg.webp";
import {useAccount} from "wagmi";
import {getChainId} from "@wagmi/core";
import {config} from "../../index";
import { apiOur } from "../../service/api/apiOur"

export const Home = (): JSX.Element => {
  const [search] = useSearchParams()
  const [loaded, setLoaded] = useState(false)
  const [announcement, setAnnouncement] = useState(false)
  const [announcementNew, setAnnouncementNew] = useState(false)
  const [ourTvlData, setOurTvlData] = useState<any[]>([])
  const [cookiesModal, setCookiesModal] = useState(false)
  const { address } = useAccount();
  const chainId = getChainId(config)

  useEffect(() => {
    if (chainId) {
      if (localStorage.getItem("swapData") !== null && chainId === 1) {
        return setOurTvlData(getFromLocalStorage("swapData"))
      }
      if (localStorage.getItem("swapDataBsc") !== null && chainId === 56) {
        return setOurTvlData(getFromLocalStorage("swapDataBsc"))
      }

      if (chainId === 56) {
        setOurTvlData(preloadDataBsc)
      }
      if (chainId === 1 || !address) {
        setOurTvlData(preloadData)
      }
    }
  }, [chainId])

  useEffect(() => {
    if (search?.get('ref') === 'j372c') {
      setToLocalStorage('ref', 'j372c')
    }
  }, [search])
  useEffect(() => {
    if (window.location.hostname === "stakedgain.finance") {
      if (address) {
        apiOur.getWithdrawals(`CLOSE_ANN_NEW_${address}`).then(r => {
          if (!r.length) {
            setAnnouncementNew(true)
          }
        })
      } else {
        setAnnouncementNew(true)
      }
    } else {
      if (address) {
        apiOur.getWithdrawals(`CLOSE_ANN_${address}`).then(r => {
          if (!r.length) {
            setAnnouncement(true)
          }
        })
      } else {
        setAnnouncement(true)
      }

    }
  }, [address])

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [])

  return (
    <div className="home" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      {!loaded && <Loading/>}
      <video className="home-video" playsInline muted autoPlay preload='auto'
             loop id="video">
        <source src={videoSrc} type="video/mp4"/>
      </video>
      <div className='roadmap-blur'/>
      <Header/>
      <div className='news-prices'>
        {
          // @ts-ignore
          (<coingecko-coin-price-marquee-widget
            coin-ids="bitcoin,ethereum,weth,binancecoin,usd-coin,uniswap,chainlink,wrapped-bitcoin,apecoin,tether,pancakeswap-token,baby-doge-coin,trust-wallet-token,stepn,coin98,aptos,optimism,matic-network,avalanche-2,arbitrum,chainlink,manta-network,fantom,dydx-chain"
            currency="usd"
            font-color="#ffffff"
            background-color="rgba(12, 14, 40, 0.32)"
            locale="en"/>)
        }
      </div>

      <Main/>
      <Features/>
      <Leverage/>
      <Partners/>
      <Code/>
      <Chains/>
      <SwapHome/>
      <Investors/>
      <Ecosystem/>
      <NewsHome/>
      {announcement && <AnnouncementModal neverRemind text="Official Announcement: StakedGain Platform Now Accessible via stakedgain.finance
Dear Users,
We are pleased to inform you that StakedGain will now primarily operate through the domain stakedgain.finance. This domain will serve as the primary access point for all staking activities and platform-related services.
Our team has worked diligently to ensure that this transition is seamless for all users, and we are confident that this change will enhance the overall platform experience. Rest assured, all funds remain secure within blockchain-based smart contracts, and this update does not affect your assets or ongoing staking activities.
What You Need to Know:
Please update your bookmarks to stakedgain.finance for easy access.
All features, services, and staking pools remain fully operational.
Our previous domain will continue to redirect users for a limited time, but we recommend using the new domain moving forward for the best experience.
We appreciate your continued trust and support. If you have any questions or encounter any issues, please don’t hesitate to reach out to our support team.
Thank you for being part of the StakedGain community!
Sincerely,
The StakedGain Team" onClose={() => setAnnouncement(false)}/>}
      {announcementNew && <AnnouncementModal neverRemind text="Official Announcement: StakedGain.com Reopens After Maintenance and Issue Resolution

Dear StakedGain Community,

We are pleased to announce that StakedGain.com has been successfully reopened following a scheduled maintenance period. During this downtime, our team worked diligently to address several user-reported issues, and we’re happy to report that these have now been resolved.

What We Fixed:
Incorrect User Flagging: Some users reported being incorrectly flagged by the system. We’ve identified and fixed this issue to ensure that all users are accurately recognized by our platform.
Connection Problems: A few users experienced connection issues that prevented them from accessing their accounts. We’ve made the necessary adjustments to stabilize the platform and ensure smooth, uninterrupted access.
We are confident that these fixes will significantly improve your experience with StakedGain, and we appreciate your patience as we addressed these challenges.

Next Steps for Users:
If you continue to experience any issues related to account access, incorrect flagging, or connection problems, we encourage you to contact our support team for assistance. Please reach out to us at support@stakedgain.com, and our team will be happy to assist you.

We remain committed to providing a seamless and secure staking experience for all our users. Your trust is our top priority, and we sincerely thank you for your continued support during this process.

Thank you for being part of the StakedGain community!" onClose={() => setAnnouncementNew(false)}/>}
      {/*{welcome && (*/}
      {/*  <RefModal*/}
      {/*    onClose={() => {*/}
      {/*      setWelcome(false)*/}
      {/*      setCookiesModal(true)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {cookiesModal && <CookiesModal onClose={() => setCookiesModal(false)}/>}
      <Footer/>
    </div>
  )
}
