import React, { useState } from "react"
import { Header, Footer, Collapse } from "components"

import "./FAQ.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"

export const FAQ = (): JSX.Element => {

  const faqData = [
    {
      title: "How is Staking work on Stakedgain?",
      desc: "" +
        "Stakedgain is a cutting-edge, decentralized staking platform that offers unique opportunities for users. Leveraging the power of well-designed smart contracts, Stakedgain is able to identify innovative staking pools that offer high potential and attractive Annual Percentage Rates (APR) in the market. This allows users to earn through arbitrage.\n" +
        "\n" +
        "The smart contracts employed by Stakedgain are not only robust and reliable but they also provide a competitive edge. Owing to the strength of their smart contract mechanism, Stakedgain is capable of offering higher returns compared to other decentralized applications (dApps) in the market.\n" +
        "\n" +
        "In essence, Stakedgain transforms how staking works by combining safety, reliability, and higher earning potential under one platform. With Stakedgain, you're not just staking — you're optimizing your assets.",
    },
    {
      title: "How can I start staking on Stakedgain?",
      desc: "To begin Staking, you need to have a compatible wallet for your chosen cryptocurrency. After connecting to the wallet , you can deposit your tokens into it. Choose the amount of tokens you wish to stake ,and follow the instruction provided to stake your tokens.",
    },
    {
      title: "Is there a minimum amount required to stake on Stakedgain?",
      desc: "The minimum staking requirement on Stakedgain may be different depending on the specific staking pool .As such ,it is advisable to check the staking pool details for accurate information regarding the minimum staking requirements.",
    },
    {
      title: "Can I withdraw my staked tokens at Stakedgain at anytime?",
      desc: "Withdrawal policies on Stakedgain may be different based on each staking pool.",
    },
    {
      title: "Is my principal investment safe while staking on Stakedgain?",
      desc: "Stakedgain has harnessed the power of smart contracts and blockchain technology to provide a secure environment for staking.However, it is important to acknowledge that all cryptocurrency investments carry a certain level of risk.",
    },
    {
      title: "How are the referral reward calculated on Stakedgain?",
      desc: "Referral rewards on Stakedgain are calculated as an additional 15% bonus on the staking interest earned by your referral friends. To maximize your referral earnings , you can share your unique referral link with friends.",
    },
    {
      title: "Do I have to stake on Stakedgain to participate into the referral program?",
      desc: "To participate in the referral program on Stakedgain ,it is necessary to actively stake any currency on the platform ,It is important to note that an invitation will only be considered valid if you are participating in staking activities,If you are not actively staking ,the invitation will be deemed invalid.",
    },
    {
      title: "Can I track the performance of my referrals on Stakedgain?",
      desc: "Stakedgain provides a user-friendly dashboard for tracking the performance of referrals,which includes details such as the number of successful referrals, and referral friend's staking activities on Stakedgain.",
    },
    {
      title: "Is my personal information safe on Stakedgain?",
      desc: "Stakedgain prioritizes the privacy and security of it's users by implementing robust measures such as data encryption and secure authentication methods to safeguard their personal information ,It is recommended that users review the platform security features thoroughly to ensure their information is well protected.",
    },
    {
      title: "Do I need an referral code to access Stakedgain?",
      desc: "As of our recent policy updates, you no longer need an referral code to join the Stakedgain platform. This change is part of our ongoing commitment to make decentralized staking accessible to a wider audience.\n" +
         "\n" +
         "While the referral-only system helped us foster a secure and exclusive environment in the initial stages, we believe it's now time to open the gates to more individuals interested in decentralized staking. \n" +
         "\n" +
         "This doesn't mean we're compromising on our commitment to security and performance. Rest assured, we continue to implement rigorous checks and safeguards to ensure the platform remains secure and reliable for all users.\n" +
         "\n" +
         "Now, anyone with an interest in digital asset management and staking can enjoy the full features and benefits of Stakedgain, joining a community of forward-thinking individuals united by a shared interest in the future of decentralized finance."
    },
    {
      title: "Is Stakedgain audited?",
      desc: "The Stakedgain smart contracts have been designed from the ground up with security in mind by using as many audited components as possible. \n" +
         "\n" +
         "We would like to thank InterFi Network for their hard work on the audits."
    },
  ]

  return (
    <div className="faq" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      <Header />
      <div className='news-prices'>
        {
          // @ts-ignore
          (<coingecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,weth,binancecoin,usd-coin,uniswap,chainlink,wrapped-bitcoin,apecoin,tether,pancakeswap-token,baby-doge-coin,trust-wallet-token,stepn,coin98,aptos,optimism,matic-network,avalanche-2,arbitrum,chainlink,manta-network,fantom,dydx-chain" currency="usd"
                                                font-color="#ffffff"
                                                background-color="rgba(12, 14, 40, 0.32)"
                                                locale="en"/>)
        }
      </div>

      <div className="faq-content">
        <h1 className="faq-content-title">FAQ</h1>
        {faqData.map((item, index) => (
          <Collapse desc={item.desc} title={item.title} key={index}/>
        ))}
      </div>
      <Footer/>
    </div>
  )
}
