export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "https://twitter.com/Staked_Gain",
  youtube: "",
  discord: "",
  scan: "",
  telegram: "https://t.me/staked_gain",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  whitePaper: "",
  contract: "",
  audit: "https://github.com/interfinetwork/project-delivery-data/blob/main/StakedGain/StakedGain_AuditReport_InterFi.pdf"
}
