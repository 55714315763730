import React, { useState } from "react"
import { Header, Footer } from "components"

import "../Terms/Terms.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"

export const Privacy = (): JSX.Element => {

  return (
    <div className="terms privacy" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      <Header/>
      <div className='news-prices'>
        {
          // @ts-ignore
          (<coingecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,weth,binancecoin,usd-coin,uniswap,chainlink,wrapped-bitcoin,apecoin,tether,pancakeswap-token,baby-doge-coin,trust-wallet-token,stepn,coin98,aptos,optimism,matic-network,avalanche-2,arbitrum,chainlink,manta-network,fantom,dydx-chain" currency="usd"
                                                font-color="#ffffff"
                                                background-color="rgba(12, 14, 40, 0.32)"
                                                locale="en"/>)
        }
      </div>

      <div className="terms-content">
        <h1 className="terms-content-title">Privacy policy</h1>
        <p className="terms-content-desc">
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
          information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </p>
        <h3 className="terms-content-title-2">Interpretation and Definitions</h3>
        <h3 className="terms-content-subtitle-2">Interpretation</h3>
        <p className="terms-content-desc">
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The
          following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        <h3 className="terms-content-subtitle-2">Definitions</h3>
        <ol className="terms-content-ul">
          For the purposes of this Privacy Policy:
          <li>
            Affiliate means an entity that controls, is controlled by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities
            entitled to vote for election of directors or other managing authority.
          </li>
          <li>
            Company (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to Stakedgain.
          </li>
          <li>
            Cookies are small files that are placed on Your computer, mobile device or any other device by a website,
            containing the details of Your browsing history on that website among its many uses.
          </li>
          <li>Country refers to: British Virgin Islands</li>
          <li>
            Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
          </li>
          <li>Service refers to the Website.</li>
          <li>
            Service Provider means any natural or legal person who processes the data on behalf of the Company. It
            refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide
            the Service on behalf of the Company, to perform services related to the Service or to assist the Company in
            analyzing how the Service is used.
          </li>
          <li>
            Usage Data refers to data collected automatically, either generated by the use of the Service or from the
            Service infrastructure itself (for example, the duration of a page visit).
          </li>
          <li>
            Website refers to Stakedgain, accessible from <a
            href="https://www.stakedgain.finance/">https://www.stakedgain.finance/</a>
          </li>
          <li>
            You means the individual accessing or using the Service, or the company, or other legal entity on behalf of
            which such individual is accessing or using the Service, as applicable.
          </li>
        </ol>
        <h3 className="terms-content-title-2">Types of Data Collected</h3>
        <h3 className="terms-content-subtitle-2">Usage Data</h3>
        <p className="terms-content-desc">
          Usage Data is collected automatically when using the Service.
          <br/>
          Usage Data may include information such as Your Device&apos;s Internet Internet Protocol address (e.g. IP
          address), browser type, browser version, the pages of our Service that You visit, the time and date of Your
          visit, the time spent on those pages, unique device identifiers and other diagnostic data.
          <br/>
          When You access the Service by or through a mobile device, We may collect certain information automatically,
          including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address
          of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique
          device identifiers and other diagnostic data.
          <br/>
          We may also collect information that Your browser sends whenever You visit our Service or when You access the
          Service by or through a mobile device.
        </p>
      </div>
      <Footer/>
    </div>
  )
}
