import React from "react"
import "./Code.scss"
import srcCodeImage from 'assets/images/code.png'

export const Code = (): JSX.Element => {
  return (
    <div className="code">
      <div className='code-left' data-aos="fade-right">
        <h3>Apply algorithms for best Rewards.</h3>
        <p>
          Stakedgain utilizes sophisticated algorithms to maximize staking rewards by analyzing market conditions and
          optimizing staking strategies in real-time.
        </p>
      </div>
      <img src={srcCodeImage} alt='code' className='code-right' data-aos="fade-left"/>
    </div>
  )
}
